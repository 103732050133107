<template>
  <Header title="Business Planning" />

  <Container>
    <h2>Business Succession Planning</h2>
    <p>
      Succession planning in an ongoing and ever changing process intended to
      minimize taxes and enable the orderly transition in ownership of a
      business or partnership.
      <br /><br />
      Business owners and professionals spend their lifetimes building a
      business out of a dream. Business pressures often make it difficult to
      step back and objectively determine how to ensure the survival, growth and
      profitable continuity of their business. Any one of a number of events,
      foreseen or unforeseen, could compromise what they have worked so hard to
      achieve. A strategy should be implemented early to deal with the following
      issues:
      <br />
      <br />
      <strong class="block mb-1">Shareholder/Partner</strong>
    </p>
    <ul class="space-y-5 mt-4 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-5">
      <li class="flex items-start lg:col-span-1">
	  <svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Sale of a business</p>
	</li>
      <li class="flex items-start lg:col-span-1">
	  <svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	  <p class="ml-3">Resignation</p>
	  </li>
      <li class="flex items-start lg:col-span-1">
	  <svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	  <p class="ml-3">Retirement</p>
	  </li>
      <li class="flex items-start lg:col-span-1">
	  <svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	  <p class="ml-3">Death</p>
	  </li>
      <li class="flex items-start lg:col-span-1">
	  <svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	  <p class="ml-3">Disability</p>
	  </li>
    </ul>
    <p class="mt-6">
      The succession of the business affects the owner-manger directly, his or
      her family, employees and customer, Consequently our firm feels that a
      succession plan is a foundation on which the future of a business is
      built.
      <br /><br />
      <strong class="block mb-1 capitalize">Our Approach and process</strong>
      Our firm assists in an evaluation process that will help illustrate some
      of the concerns and questions faced by our clients. Some of these concerns
      would typically include:
    </p>
    <ul>
      <li>
        Exit plan for business owner, shareholder or partner
        <ul class="mt-0">
          <li>Review of triggering events</li>
        </ul>
      </li>

      <li class="mt-1">
        Taxation issues surrounding this process
        <ul class="mt-0">
          <li>Reducing or eliminating the tax liability</li>
          <li>Deferring the tax payment</li>
          <li>Freezing the tax bill</li>
        </ul>
      </li>

      <li class="mt-1">
        Review and structural planning of business agreements
        <ul class="mt-0">
          <li>Funding methods and options for the business agreements</li>
          <li>
            In the case of family business, equalization for the active members
          </li>
        </ul>
      </li>

      <li class="mt-1">
        The affect of business planning on one's personal and family affairs
      </li>
    </ul>
    <p class="mt-6">
      <strong class="block mb-1">To Summarize</strong>
      This is a brief overview of the process and some of the questions that all
      family businesses closely held corporations and professional partnerships
      need to examine at some point. Timing is of the essence. Obviously, no two
      situations are the same, so personal, custom tailoring is essential to
      meet business, family and one's own personal objectives.
    </p>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Container,
    Header,
  },
};
</script>
